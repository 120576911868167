App.handleSwitch = function () {

    var $switcher = $('[data-role=switcher]');
    var groups = [];

    $switcher.each(function () {
        var groupTitle = $(this).attr('data-switchgroup');

        if ($.inArray(groupTitle, groups) === -1) {
            groups.push(groupTitle);
        }
    });

    $.each(groups, function (i, val) {
        var $group = $('[data-switchgroup=' + val + ']');

        // Altijd 1 active
        if(!$group.filter('[data-state=active]').length){
            var item = $group.filter('[data-role=switcher]').first().attr('data-switchitem');

            $group.filter('[data-switchitem=' + item + ']').attr('data-state', 'active');
        }

        $group.filter('[data-role=switcher]').each(function () {
            $(this).on('click', function () {

                var state = $(this).attr('data-state');                
                var item = $(this).attr('data-switchitem');

                if (state === 'active') {
                    state = 'passive';
                } else {
                    state = 'active';
                    $group.attr('data-state', 'passive');
                    $group.filter('[data-switchitem=' + item + ']').attr('data-state', state);
                }                
            });
        });
    });
};
