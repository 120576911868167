import Cookies from 'js-cookie';

document.addEventListener('DOMContentLoaded', (event) => {
    const gtmcookie = document.querySelector('[data-nbcookie="gtmcookie"]');

    if (gtmcookie) {

        var popupcookie = Cookies.get('gtmCookiePopup');

        if (!popupcookie) {
            gtmcookie.setAttribute('data-nbcookiestate', 'active');
        }

        $('.nb--cookiepopup__lang-dropdown').on('change', function () {
            $('div.nb--cookiepopup__translated').addClass('hidden');
            $('div.nb--cookiepopup__translated-' + $(this).val()).removeClass('hidden');
        });

        $('.nb--cookiepopup__field--radio input').on('change', function () {
            $('.nb--cookiepopup__required').hide();
        });

        // COOKIE POPUP OPENEN BIJ KLIK
        $('.js--nb-cookie-settings').on('click', function (e) {
            e.preventDefault();
            gtmcookie.attr('data-nbcookiestate', 'active');
            $('.nb--cookiepopup__intro').hide();
            $('.nb--cookiepopup__settings').show();
            if (popupcookie == 0) {
                $('input[name=nbcookie][value=minimal]').prop('checked', true);
            } else if (popupcookie == 1) {
                $('input[name=nbcookie][value=optimal]').prop('checked', true);
            }
        });

        $('.js--nb-gtmcookie').on('click', function (e) {
            e.preventDefault();
            var answer = $(this).data('answer');

            Cookies.remove('gtmCookieAccepted');

            if (answer == 'accept') {
                nbcookieAccepted();
            } else if (answer == 'settings') {
                $('.nb--cookiepopup__intro').hide();
                $('.nb--cookiepopup__settings').show();
            } else if (answer == 'radio') {
                var i = $('input[type=radio][name=nbcookie]:checked').val();
                if (i) {
                    if (i == 'optimal') {
                        nbcookieAccepted();
                    } else {
                        nbcookieDeclined();
                    }
                } else {
                    $('.nb--cookiepopup__required').show();
                }
            } else {
                nbcookieDeclined();
            }
        });

        function nbcookieAccepted() {
            Cookies.set('gtmCookieAccepted', 1, {expires: 1095});
            Cookies.set('gtmCookiePopup', 1, {expires: 1095});
            // Reload page
            location.reload();
        }

        function nbcookieDeclined() {
            Cookies.set('gtmCookieAccepted', 0, {expires: 1095});
            Cookies.set('gtmCookiePopup', 1, {expires: 1095});
            // Reload page
            location.reload();
        }
    }
}, false);