App.handleCarousel = function () {

    $('[data-role=carousel]').slick({
        infinite:      false,
        speed:         300,
        cssEase:       'ease',
        slidesToShow:  3,
        arrows:        true,
        prevArrow:     '<button type="button" class="carousel__arrow carousel__arrow--left  slick-prev"><svg class="carousel__arrow-icon"><use xlink:href="/public/gfx/icons.svg#icon-arrow-right"></use></svg></button>',
        nextArrow:     '<button type="button" class="carousel__arrow carousel__arrow--right slick-next"><svg class="carousel__arrow-icon"><use xlink:href="/public/gfx/icons.svg#icon-arrow-right"></use></svg></button>',
        draggable:     false,
        focusOnSelect: false,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
        
    });
};