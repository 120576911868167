App.handleProductFilter = function () {

    var spinningElement = $('.js-product--list .loading-spinner').detach();
    var filterArray = ['brand', 'grip', 'material'];
    init();

    function init() {
        // TODO: getUrlParameter & set all fields
        setSlider('uvAbsorption');
        setSlider('viscosityDigit');
    }

    function controlParams(key, val) {
        var $path = '';
        $('.js-product--list').empty().append(spinningElement);

        $('.loading-spinner').show();
        $('html, body').animate({
            scrollTop: $("body").offset().top
        }, 250);

        if (val) {
            $path = document.location.pathname + addUrlParam(document.location.search, key, val);
            if (key !== 'page') {
                $path = removeURLParameter($path, 'page');
            }
        } else {
            $path = removeURLParameter(window.location.href, key);
        }

        if(key === 'cats') {
            $.each(filterArray, function(index, value) {
                $path = removeURLParameter($path, value);
            });
        }

        ajaxCall($path);
    }

    function ajaxCall(path) {
        window.history.pushState("", "", path);

        $.ajax({
            url: path,
            data: {'ajax' : 1},
            success: function (response) {
                $('.loading-spinner').hide();
                $('.js-product--list').html(response.listingHtml);
            }
        });
    }

    function getUrlParameter(sParam, linkPart) {
        if (linkPart == null) {
            linkPart = window.location.search.substring(1);
        }

        var sPageURL = decodeURIComponent(linkPart),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
    }

    function addUrlParam(search, key, val) {
        var newParam = key + '=' + val,
            params = '?' + newParam;

        // If the "search" string exists, then build params from it
        if (search) {
            // Try to replace an existance instance
            params = search.replace(new RegExp('([?&])' + key + '[^&]*'), '$1' + newParam);
            // If nothing was replaced, then add the new param to the end
            // If it isnt already in the params
            if (params === search && Array.isArray(val) == false && key.indexOf('[from]') == -1 && key.indexOf('[to]') == -1) {
                if (params.indexOf(newParam) == -1) {
                    params += '&' + newParam;
                }
            } else if(params === search && Array.isArray(val) == true) {
                key = key.replace('[]', '');
                params = search.replace(new RegExp('([?&])' + key + '[^&]*'), '$1' + newParam);
                if (params === search) {
                    if (params.indexOf(newParam) == -1) {
                        params += '&' + newParam;
                    }
                }
            } else if(params === search && key.indexOf('[from]') > -1) {
                key = key.replace('[from]', '');
                params = search.replace(new RegExp('([?&])'+key+'\\[from\\][^&]*', 'ig'), '$1' + newParam);
                if (params === search) {
                    if (params.indexOf(newParam) == -1) {
                        params += '&' + newParam;
                    }
                }
            } else if(params === search && key.indexOf('[to]') > -1) {
                key = key.replace('[to]', '');
                params = search.replace(new RegExp('([?&])'+key+'\\[to\\][^&]*', 'ig'), '$1' + newParam);
                if (params === search) {
                    if (params.indexOf(newParam) == -1) {
                        params += '&' + newParam;
                    }
                }
            }
        }
        return params;
    }

    function removeURLParameter(url, parameter) {
        //prefer to use 1 part.search if you have a location/link object
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {

            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);

            //reverse iteration otherise the url parts may be lost
            for (var i = pars.length; i-- > 0;) {
                //idiom for string.startsWith
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }

            url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");
            return url;
        } else {
            return url;
        }
    }

    function removeFromURLParameter(name, remove) {
        var currentValue = getUrlParameter(name);

        if (currentValue == null) {
            return null;
        }

        if(typeof remove === 'undefined') {
            return null;
        }

        var array = currentValue.split(','),
            index = array.indexOf(remove.toString());

        if (index > -1) {
            array.splice(index, 1);
        }

        if (array.length > 0) {
            return array;
        } else {
            return null;
        }
    }

    // When user select checkbox filters, add this to the filter.
    $('.js--filtercontent').on('change', '.checklist_filters', function () {
        var $name = $(this).attr('name');
        var value = $(this).val();
        //Clear the current filters
        var selectedValue = [];
        $(this).parents('.filterlist').find('.checklist_filters:checked').each(function () {
            selectedValue.push($(this).val());
        });

        controlParams($name, selectedValue);
    });

    // When user select checkbox filters, add this to the filter.
    $('.js--filtercontent').on('change', '.radio_filters', function () {
        var name = $(this).attr('name'),
            value = $(this).val();

        if (value === '') {
            value = null;
        }

        controlParams(name, value);
    });

    // When user select option filters, add this to the filter.
    $('.js--filtercontent').on('change', '.select_filters', function () {
        var name = $(this).attr('name'),
            value = $(this).val();

        if (value === '') {
            value = null;
        }

        controlParams(name, value);
    });

    // When user clicks category, add this to the filter.
    $('.category_filter').on('click', function(e) {
        e.preventDefault();
        $name = $(this).data('filtername');

        var selectedValue = [];
        selectedValue.push($(this).data('filtervalue'));

        controlParams($name, selectedValue);

        $('.aside__list li').each(function(){
            $(this).removeClass('active');
            $(this).removeClass('active-parent');
        });

        $(this).parent().addClass('active');
        $(this).closest('.aside__list-item').addClass('active-parent');
    });

    $('input[name="search"]').keyup(function(e) {
        if(e.keyCode == 13) {
            $name = $(this).data('filtername');

            var selectedValue = [];
            selectedValue.push($(this).val());

            controlParams($name, selectedValue);
        }
    });

    // Clear filter based on name
    $('.js-product--filters').on('click', '.clear-filters', function () {
        var $name = $(this).data('filtername');
        $selectedValue = removeFromURLParameter($name);

        $('input[name="'+$name+'"]').prop('checked', false);

        controlParams($name, $selectedValue);
    });

    // Add page clicks to the filter
    $('.pagination').on('click', '.pagelink', function (e) {
        e.preventDefault();

        var $selectedValue = $(this).data('pagevalue');
        var $name = 'page';

        $(window).scrollTop(0);
        controlParams($name, $selectedValue);

        return false;
    });

    function setSlider(elementName){

        // Range slider filter
        var slMin = parseFloat($('input[name="' + elementName + 'Min"]').val());
        var slMax = parseFloat($('input[name="' + elementName + 'Max"]').val());
        var slFrom = parseFloat($('input[name="' + elementName + 'From"]').val());
        var slTo = parseFloat($('input[name="' + elementName + 'To"]').val());

        if(isNaN(slFrom)){
            slFrom = slMin;
        }
        if(isNaN(slTo)){
            slTo = slMax;
        }

        $('#slider-range-' + elementName).slider({
            range: true,
            min: slMin,
            max: slMax,
            values: [slFrom, slTo],
            slide: function(event, ui) {
                $('#amount-' + elementName).val(ui.values[0] + ' - ' + ui.values[1]);
            }
        });

        $('#amount-' + elementName).val($('#slider-range-' + elementName).slider('values', 0) + ' - ' + $('#slider-range-' + elementName).slider('values', 1));

        // Als je de slider loslaat
        $('#slider-range-' + elementName).on("slidestop", function(event, ui) {
            controlParams(elementName + "[from]", ui.values[0]);
            controlParams(elementName + "[to]", ui.values[1]);
        });
    }

};
