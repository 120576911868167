App.handleScroll = function(){

    // SMOOTH SCROLL TO ANCHOR ON CLICK
  	// $('a[href*="#"]:not([href="#"])').click(function() {
	// 	if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
	// 		var target = $(this.hash);
	// 		if (target.length) {
	// 			$('html, body').animate({
	// 				scrollTop: target.offset().top
	// 			}, 1000);
	// 			return false;
	// 		}
	// 	}
	// });

	var viewportTop;
    var scrollState;
    var changeScrollState;

	// DIFFERENTIATE BETWEEN TOP OR SCROLLED POSITION
	$(document).on('scroll', function(){
		viewportTop = $(document).scrollTop();

		if (viewportTop > 0 && scrollState != 'scrolled') {
			scrollState = 'scrolled';
			changeScrollState = true;
		} else if (viewportTop == 0 && scrollState != 'top'){
			scrollState = 'top';
			changeScrollState = true;
		} else{
			changeScrollState = false;
		}

		if (changeScrollState){
			$('body').attr('data-state', scrollState);
			// $scrollStart.each(function(){
			// 	$(this).attr('data-state', scrollState);
			// });
		}
	});
};
