import GLightbox from 'glightbox'

App.handleLightbox = function () {
  const images = document.querySelectorAll('.lightbox');

  if (images.length > 0) {
    const lightbox = GLightbox({
      elements: [...images].map((image, index) => {
        const slide = {
          href: '',
          type: 'image',
        };

        if (image.getAttribute('src').length > 0) {
          slide.href = image.getAttribute('src');

          image.addEventListener('click', () => {
            lightbox.openAt(index);
          })
        }

        return slide;
      }).filter(slide => slide.href.length > 0)
    });
  }
};