/**
 * General toggle class.
 * Toggles data-state attribute.

 * toggler: click handler, controls the state.
 * togglee: container for toggleable content

 * data-togglerId = Control group. Can have multiple togglers, togglees
 * data-state = state of group, can be either passive or active
 * data-role = determines if togglee or toggler (only toggler gets bind handler)

 * html example toggler (can be anything, controls state)
 * <div data-togglerId="menu" data-role="toggler" data-state="passive"></div>

 * html example togglee (can be anything, state controlled by toggler)
 * <div class="menu"  data-togglerId="menu" data-role="togglee" data-state="passive"></div>
 */
  
App.handleToggle = function() {

    var $toggler = $('[data-role=toggler]'); // gets all togglers to determine groups
    var $togglee = $('[data-role=togglee]'); // doesn't do anything atm. prepared for future extension
    var groups = []; // gets all groups available on page

    /**
     * Loops over available togglers to determine groups
     * gets all group titles and checks if exists in array test
     * if it doesn't exist adds to groups array
     */
    $toggler.each(function() {
        var groupTitle = $(this).attr('data-togglerId');

        if ($.inArray(groupTitle, groups) === -1) {
            groups.push(groupTitle);
        }
    });

    /**
     * Loops over groups in array.
     * Determines group state (passive or active)
     * for each group gets available togglers
     * handles on click for each toggler in group
     * adds state to all elements in group
     */
    $.each(groups, function(i, val) {
        var $group = $('[data-togglerId=' + val + ']');
        var state = $group.attr('data-state');
        $group.filter('[data-role=toggler]').each(function() {
            $(this).on('click', function(){
                if (state === 'active') {
                    state = 'passive';
                } else {
                    state = 'active';
                }
                $group.attr('data-state', state);
            });
        });
    });
};
